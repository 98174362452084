<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { ReteynSchema } from "../schema";

    export let testInfo: ReteynSchema["testInfo"]["returnType"];
    const dispatch = createEventDispatcher<{answer: string}>();
</script>

{#if testInfo}
    <div class="flex flex-col text-center items-center justify-center w-full full-height gap-10">
        <p class="font-bold w-4/5">
            {testInfo.question}
        </p>
        <div class="flex flex-col gap-4 w-4/5">
            {#each testInfo.answers as answer}
                <button class="btn btn-primary w-full" on:click={() => dispatch("answer", answer.id)}>{answer.text}</button>
            {/each}
        </div>
    </div>
{/if}