<script lang="ts">
    import { ReteynSchema } from "../schema";
    import ValidityMessage from "./ValidityMessage.svelte";
    import GifMoji from "./GifMoji.svelte";
    import { Chevron } from "../reteyn-components";
    import { createEventDispatcher } from "svelte";
    export let submission: ReteynSchema["testSubmission"]["returnType"];
    export let quizStatsPromise: Promise<
        ReteynSchema["quizStats"]["returnType"]
    >;

    let showAnswer: boolean = false;
    const dispatch = createEventDispatcher<{ nextQuestion: void }>();
</script>

<div class="full-height flex flex-col items-center text-center justify-evenly gap-4 w-full">
    <div class="flex flex-col gap-4 items-center text-center w-full">
    {#if showAnswer}
        <ValidityMessage
            {quizStatsPromise}
            answer={{ correct: !!submission?.correct }}
            class="mt-10 w-4/5"
        />
        <p class="w-4/5 text-pretty">
            {submission?.response}
        </p>
        <p class="font-bold mt-10 w-4/5 text-pretty">Fun Fact:</p>
        <p class="w-4/5 text-pretty">
            {submission?.fact}
        </p>
    {:else}
        <GifMoji correct={!!submission?.correct} />
        <ValidityMessage
            {quizStatsPromise}
            answer={{ correct: !!submission?.correct }}
            class="w-4/5"
        />
    
    {/if}
    </div>
    <div class="flex sm:flex-col justify-center gap-4 w-4/5">
        {#await quizStatsPromise}
            <div class="skeleton h-12 w-28"></div>
        {:then quizStats}
            {#if !showAnswer}
                <button
                    class="btn btn-primary"
                    on:click={() => (showAnswer = true)}
                >
                    Tell me why
                </button>
            {/if}
            {#if quizStats.nextQuestionId}
                <button
                    class="btn btn-primary"
                    on:click={() => dispatch("nextQuestion")}
                >
                    Next question
                    <div class="w-3 h-3">
                        <Chevron class="fill-base-100 h-3 w-3 -rotate-90" />
                    </div>
                </button>
            {/if}
        {/await}
    </div>
</div>
