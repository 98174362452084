import { Readable, readable } from "svelte/store";
import { generateClient } from "aws-amplify/api";
import type { ReteynSchema } from "../schema/index.js";
import type { V6Client } from "@aws-amplify/api-graphql";

const client = generateClient<ReteynSchema>({
  authMode: "identityPool",
});

export const clientStore: Readable<V6Client<ReteynSchema>> = readable(client);
