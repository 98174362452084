<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { ReteynSchema } from "../schema";
    import Question from "./Question.svelte";
    import Response from "./Response.svelte";
    import ReteynerMastery from "./ReteynerMastery.svelte";
    import CentralContent from "./CentralContent.svelte";
    import TruthyLoader from "./TruthyLoader.svelte";
    import { Chevron } from "../reteyn-components";

    const dispatch = createEventDispatcher<{answer: string, nextQuestion: void}>();

    export let testInfoPromise: Promise<ReteynSchema["testInfo"]["returnType"]>;
    export let quizStatsPromise: Promise<ReteynSchema["quizStats"]["returnType"]>;
    export let submissionPromise: Promise<ReteynSchema["testSubmission"]["returnType"]>;

</script>


<TruthyLoader promise={testInfoPromise} let:result>
    {#if result}
        {@const testInfo = result}
        <div class="full-height mt-10 w-full flex flex-col gap-4">
            <div class="w-full px-4">
                <ReteynerMastery {testInfo} {quizStatsPromise} {submissionPromise}/>
            </div>
            <div class="full-height flex flex-col justify-center items-center w-full">
                {#await submissionPromise}
                    <CentralContent>
                        <TruthyLoader promise={submissionPromise} />
                    </CentralContent>
                {:then submission}
                    {#if submission}
                        <div class="full-height flex flex-col justify-center items-center py-4 w-full gap-4">
                            <Response {submission} {quizStatsPromise} on:nextQuestion={() => dispatch("nextQuestion")}/>
                        </div>
                    {:else}
                        <Question {testInfo} on:answer={event => dispatch("answer", event.detail)}/>
                    {/if}
                {/await}
            </div>
            
        </div>
    {/if}
</TruthyLoader>
