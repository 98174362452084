<script lang="ts">
    import type { ReteynSchema } from "../schema";
    export let testInfo: ReteynSchema["testInfo"]["returnType"];
    export let quizStatsPromise: Promise<
        ReteynSchema["quizStats"]["returnType"]
    >;
    export let submissionPromise: Promise<
        ReteynSchema["testSubmission"]["returnType"]
    >;

    function toCorrectCount(
        quizStats: ReteynSchema["quizStats"]["returnType"],
        submission: ReteynSchema["testSubmission"]["returnType"],
    ): number {
        return (
            quizStats.distinctCorrectSubmissions +
            (quizStats.previousAnswerCorrect && !submission?.correct
                ? -1
                : !quizStats.previousAnswerCorrect && submission?.correct
                  ? 1
                  : 0)
        );
    }
</script>

{#if testInfo}
    <div class="w-full flex flex-col gap-2">
        <div class="flex gap-4 wrap justify-between">
            <p class="font-bold">
                {testInfo.reteynerName}
            </p>
            {#await Promise.all([quizStatsPromise, submissionPromise])}
                <span class="loading loading-ring loading-md"></span>
            {:then [quizStats, submission]}
                {@const totalCorrect = toCorrectCount(quizStats, submission)}
                {@const mastery = totalCorrect / quizStats.questions}
                <p>
                    {#if totalCorrect >= quizStats.questions}🎓{:else}Mastery: {Math.min(
                            Math.round(100 * mastery),
                            100,
                        )}%{/if}
                </p>
            {/await}
        </div>
        {#await Promise.all([quizStatsPromise, submissionPromise])}
            <progress class="progress w-full progress-primary" />
        {:then [quizStats, submission]}
            {@const totalCorrect = toCorrectCount(quizStats, submission)}
            <progress
                class="progress w-full progress-primary"
                value={totalCorrect}
                max={quizStats.questions}
            />
        {/await}
    </div>
{/if}
