<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { ReteynSchema } from "../schema";
    import { QuizApi } from "./QuizApi";
    import TruthyLoader from "./TruthyLoader.svelte";

    export let testId: string;
    export let answerId: string;
    export let api: QuizApi;

    const dispatch = createEventDispatcher<{submitted: void}>();

    let submissionPromise: Promise<ReteynSchema["testSubmission"]["returnType"]>;

    submissionPromise = api.submit({testId, answerId}).then(r => {
        dispatch("submitted");
        return r;
    });

</script>

<TruthyLoader promise={submissionPromise} />
