<script lang="ts">
    import LinkError from "./LinkError.svelte";
    import Loader from "./Loader.svelte";

    export let promise: Promise<any>;
</script>

<Loader {promise} let:result>
    {#if !result}
        <LinkError>Invalid link.</LinkError>
    {:else}
        <slot {result}/>
    {/if}
</Loader>
