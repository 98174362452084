<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { ReteynSchema } from "../schema";
    import Quiz from "./Quiz.svelte";
    import { QuizApi } from "./QuizApi";
    import Loader from "./Loader.svelte";

    export let api: QuizApi;
    export let testId: string;
    
    let nextTestPromise: Promise<void> = Promise.resolve();

    const dispatch = createEventDispatcher<{nextQuestion: string}>()
    let testInfoPromise: Promise<ReteynSchema["testInfo"]["returnType"]>;
    
    let submissionPromise: Promise<
        ReteynSchema["testSubmission"]["returnType"]
    >;
    let quizStatsPromise: Promise<ReteynSchema["quizStats"]["returnType"]>;

    $: testInfoPromise = api.testInfo({testId});

    $: submissionPromise = api.testSubmission({testId});

    $: quizStatsPromise = api.quizStats({testId});

    async function nextQuestion(): Promise<void> {
        const response = await api.nextTest({testId});
        if (response) {
            dispatch("nextQuestion", response!.testId);
        }
        
    }
</script>

<Loader promise={nextTestPromise}>
    <Quiz
        {testInfoPromise}
        {quizStatsPromise}
        {submissionPromise}
        on:nextQuestion={() => nextTestPromise = nextQuestion()}
        on:answer={(event) =>
            (submissionPromise = api.submit({
                testId,
                answerId: event.detail,
            }))}
    />
</Loader>

