import type { V6Client } from "@aws-amplify/api-graphql";
import type { ReteynSchema } from "../schema";
import { parseReturnValue, parseTruthyResponse } from "../dao";

export class QuizApi {
  constructor(
    private client: V6Client<ReteynSchema>,
    private responseCache: Record<
      `${keyof V6Client<ReteynSchema>["queries"]}.${string}`,
      any
    > = {},
  ) {}

  async cache<T>(
    key: `${keyof V6Client<ReteynSchema>["queries"]}.${string}`,
    executor: () => Promise<T>,
  ): Promise<T> {
    this.responseCache[key] = this.responseCache[key] || (await executor());
    return this.responseCache[key];
  }

  async nextTest(request: {
    testId: string;
  }): Promise<ReteynSchema["nextTest"]["returnType"]> {
    return parseTruthyResponse(this.client.mutations.nextTest(request));
  }

  async submit(request: {
    testId: string;
    answerId: string;
  }): Promise<ReteynSchema["submit"]["returnType"]> {
    const response = await parseTruthyResponse(
      this.client.mutations.submit(request),
    );
    this.responseCache[`testSubmission.${request.testId}`] = response;
    return response;
  }

  async testInfo(request: {
    testId: string;
  }): Promise<ReteynSchema["testInfo"]["returnType"]> {
    return this.cache(`testInfo.${request.testId}`, () =>
      parseTruthyResponse(this.client.queries.testInfo(request)),
    );
  }

  async quizStats(request: {
    testId: string;
  }): Promise<ReteynSchema["quizStats"]["returnType"]> {
    return this.cache(`quizStats.${request.testId}`, () =>
      parseTruthyResponse(this.client.queries.quizStats(request)),
    );
  }

  async testSubmission(request: {
    testId: string;
  }): Promise<ReteynSchema["testSubmission"]["returnType"]> {
    return this.cache(`testSubmission.${request.testId}`, () =>
      parseReturnValue(this.client.queries.testSubmission(request)),
    );
  }
}
