export enum Route {
  Edit = "edit",
  Update = "update",
  SignIn = "signin",
  Submit = "submit",
  Create = "create",
  Result = "result",
  Import = "import",
  Quiz = "quiz",
}
