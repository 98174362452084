export enum CustomFunctionName {
  SubmitHandler = "submitHandler",
  StudentsHandler = "studentsHandler",
  TestsHandler = "testsHandler",
  ReteynerHandler = "reteynerHandler",
  ReportHandler = "reportHandler",
  QuestionHandler = "questionHandler",
  TestInfoHandler = "testInfoHandler",
  PreviousTestsHandler = "previousTestsHandler",
  QuizStatsHandler = "quizStatsHandler",
  NextTestHandler = "nextTestHandler",
  TestSubmissionHandler = "testSubmissionHandler",
}
